import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import {extractTitle, renderAst} from "./common";

// Oh man, this is amusingly terrible :D.
import "../styles/markdown_pages.sass";
import PageHelmet from "../components/PageHelmet";


export default function Template(fluff: any) {
  const pageName = fluff.location.pathname.slice(1);  // eg. "company".
  return <Layout route={fluff.path}>
    <PageHelmet title={extractTitle(fluff.data.markdownRemark.htmlAst)} description={fluff.data.markdownRemark?.frontmatter?.GoogleSummary}/>
    <div className={`markdownPage ${pageName}`}>
      <div className={`markdownContent ${pageName}`}>
        {renderAst(fluff.data.markdownRemark.htmlAst)}
      </div>
    </div>
  </Layout>;
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        GoogleSummary
      }
    }
  }
`;
